import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_COMPANY_NAME} - Privacy Policy`
  }, []);
  return (
    <Grid container style={{padding: '2em'}} spacing={4}>
      <Grid item xs={12}>
        <Typography variant={"h2"}>
          Privacy Policy
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          What information do we collect?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          We collect information from you when you fill out a form.
          When filling out contact form on our site, as appropriate, you will be asked to enter your: name, e-mail address, phone number, role and organisation.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          What do we use your information for?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          Any of the information we collect from you will be be used to contact you to answer your enquiry
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          Do we use cookies?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          Yes (Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          Do we disclose any information to outside parties?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          Online privacy policy only?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          This online privacy policy applies only to information collected through our website and not to information collected offline.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          Your consent
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          By using our site, you consent to our privacy policy.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h4"}>
          Changes to our privacy policy
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"body1"}>
          If we decide to change our privacy policy, these changes will be posted on this page.
        </Typography>
      </Grid>
    </Grid>
  )
}
