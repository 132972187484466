import React from "react";
import {makeStyles} from "@mui/styles";
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import {Button, Grid, Typography} from "@mui/material";
import {Link} from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.common.quetraBlack,
    width: "100%",
    padding: '1em',
    position: 'relative'
  },
  logo: {
    height: '4em',
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '6px',
    marginTop: '6px',
    [theme.breakpoints.down("md")]: {
      marginTop: '7px',
      height: '2rem',
    }
  },
  link: {},
  socialContainer: {
    right: "1.5em",
    marginTop: "-3.5em"
  }
}))

export default function Footer() {
  const classes = useStyles()
  return (
    <footer>
      <Grid container justify={"flex-start"} direction={"row"}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item>
            <Typography variant={"subtitle2"}>© Copyright Quetra Consulting
              Limited {new Date().getFullYear()}</Typography>
          </Grid>
          <Grid item>
            <Typography variant={"subtitle2"}>Company registration 14431693</Typography>
          </Grid>
          <Grid item>
            <Button component={Link}
                    to={"/privacy"}
            style={{padding:"0", marginTop:"-1em"}}>
              <Typography variant={"subtitle2"}
                          color={"white"}
              >
                Privacy Policy
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify={"flex-end"} direction={"row-reverse"} alignItems={"center"}
              className={classes.socialContainer}>
          <Grid item>
            <TwitterIcon fontSize={"large"}
                         onClick={() => window.open('https://www.twitter.com', '_blank')}/>
          </Grid>
          <Grid item>
            <LinkedInIcon fontSize={"large"}
                          onClick={() => window.open('https://www.linkedin.com/in/ianjdavies/', '_blank')}/>
          </Grid>
          <Grid item>
            <EmailIcon fontSize={"large"}
                       onClick={() => window.open('mailto://hayden.smith@gmail.com', '_blank')}/>
          </Grid>
        </Grid>
      </Grid>

    </footer>
  )
}
