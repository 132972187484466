import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import {createTheme, Grid, Typography} from "@mui/material";
import { SketchPicker } from 'react-color'

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} style={{backgroundColor:'white'}} />
        </Draggable>
    );
}

export default function ThemeManager(props) {
    const {open, handleClose, theme, handleThemeChange} = props

    const onHeaderChange=(color)=>{
        const temp =  {...theme}
        temp.palette.headerBackground.main = color.hex
        return handleThemeChange(createTheme(temp))
    }
    const onBackgroundChange=(color)=>{
        const temp =  {...theme}
        temp.palette.background.default = color.hex
        temp.palette.background.paper = color.hex

        return handleThemeChange(createTheme(temp))
    }
    const onHighlightChange=(color)=>{
        const temp =  {...theme}
        temp.palette.menuItem.highlight = color.hex
        temp.palette.secondary.main = color.hex
        return handleThemeChange(createTheme(temp))
    }
    return (
        <Dialog
            fullWidth
            maxWidth={"lg"}
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            componentsProps={{
                backdrop:{
                    style:{ backgroundColor: "transparent" }
            }
            }}
        >
            <DialogTitle style={{ cursor: 'move' }} color={"black"} id="draggable-dialog-title">
                Choose Theme Components
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Change each color to test your schemes. When you are happy, send each HEX Code to me
                </DialogContentText>
                <Grid container  justifyContent={"center"} alignItems={"center"} alignContent={"center"} justifyItems={"center"}>
                    <Grid xs={4} item>
                        <Typography variant={"body1"} color={"black"}>Header</Typography>
                        <SketchPicker color={theme.palette.headerBackground.main} onChangeComplete={onHeaderChange}/>
                    </Grid>
                    <Grid xs={4} item>
                        <Typography variant={"body1"} color={"black"}>Background</Typography>
                        <SketchPicker color={theme.palette.background.default} onChangeComplete={onBackgroundChange}/>
                    </Grid>
                    <Grid xs={4} item>
                        <Typography variant={"body1"} color={"black"}>Highlight Text</Typography>

                        <SketchPicker color={theme.palette.menuItem.highlight} onChangeComplete={onHighlightChange}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
