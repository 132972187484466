import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import office_shot from "../../images/office_shot.jpeg";
import theme from "../../Theme";

export default function Home() {
    useEffect(() => {
        document.title = `${process.env.REACT_APP_COMPANY_NAME}`
    }, []);
    return (
        <Grid container columns style={{padding: "1em", alignItems: "center"}}>
            <Grid item xs={4}>
                <Typography variant={"h2"}>
                    The Bestest Consultancy in the world
                </Typography>

            </Grid>
            <Grid item xs={4}>
                <Typography variant={"body1"}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis lorem viverra, porttitor justo
                    id, tristique massa. Vivamus dapibus orci ornare, aliquet orci ac, dapibus libero. Aliquam molestie
                    maximus felis et suscipit. Cras blandit molestie feugiat. Aliquam quis felis at eros mollis blandit
                    sit amet quis est. Donec eget nisl est. Mauris dapibus dolor vel tincidunt lacinia. Quisque ut
                    placerat lorem. Aliquam in nisl feugiat, finibus dolor id, luctus nibh. Morbi sed ullamcorper massa.
                    Vivamus quis metus convallis, aliquam ex id, blandit nulla. Vestibulum ante ipsum primis in faucibus
                    orci luctus et ultrices posuere cubilia curae; In hac habitasse platea dictumst. Quisque et mi quis
                    ante condimentum convallis eget sed lacus.

                    Praesent sit amet arcu non arcu consectetur luctus sit amet vel nibh. In hac habitasse platea
                    dictumst.
                </Typography>

            </Grid>
            <Grid item xs={8} style={{alignItems:"flex-end", alignContent:"flex-end"}}>
                <img alt="shot of office" src={office_shot}
                     style={{width: "100%", border: `1px solid ${theme.palette.common.quetraBlack}`}}/>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"body1"}>
                    Morbi nec sem aliquam, placerat est eget, molestie tellus. Nam sollicitudin interdum mauris in
                    malesuada. Fusce feugiat lacinia sapien eget imperdiet. Proin efficitur consectetur purus eget
                    pretium. Cras ante augue, ornare in libero at, ultricies auctor orci.

                    Proin non elit venenatis, tristique lorem a, convallis eros. Maecenas maximus bibendum tellus,
                    mollis consequat nisl ullamcorper eget. Morbi in maximus lectus, quis luctus neque. Nulla quis
                    mollis nibh. Mauris fermentum pretium massa at bibendum. Vivamus ornare ipsum at arcu hendrerit
                    posuere. Mauris sit amet gravida ipsum. Aliquam at ante eu nunc condimentum elementum eget in urna.
                    Curabitur odio nibh, efficitur non risus in, vehicula viverra tortor. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Ut euismod lacinia tincidunt. Nunc pretium sapien sit amet tellus
                    finibus, vitae iaculis tellus semper. Sed at interdum mi. Vestibulum mollis, tellus in fringilla
                    interdum, arcu quam interdum sem, et vestibulum enim nisl ut nibh.

                    Suspendisse nisi nunc, consectetur molestie mauris id, tempor commodo eros. Sed aliquet tellus
                    lectus, interdum blandit sem fringilla eget. Nam volutpat, ante sed rhoncus aliquam, odio ligula
                    lacinia dui, in laoreet enim quam at massa. Vestibulum porttitor, nunc vel convallis mattis, nisi
                    odio iaculis augue, non venenatis ipsum nibh at nunc. Donec maximus vehicula felis, ut tempor magna
                    accumsan id. Donec facilisis bibendum leo, in pharetra lacus. Proin rutrum arcu sem, et facilisis
                    nisi iaculis sed. Sed posuere laoreet ipsum, non tristique eros. Praesent dictum mauris ut vulputate
                    bibendum. Mauris molestie turpis id eros bibendum, sed interdum urna accumsan. In in facilisis
                    metus, sollicitudin dignissim dui. Nunc vitae maximus erat, a ornare magna. In gravida tempor urna,
                    eget molestie dui congue sollicitudin. Vivamus scelerisque pulvinar egestas.

                    Nulla scelerisque ac massa id lacinia. Integer rhoncus odio non elit laoreet consequat. Aenean at
                    massa mauris. Mauris non pulvinar eros, in mattis orci. Phasellus maximus porttitor leo, eu
                    consequat massa ullamcorper ut. Integer eu odio aliquet, aliquam ante ut, consectetur neque. In
                    blandit ullamcorper hendrerit.
                </Typography>
            </Grid>

        </Grid>
    )
}
