import './App.css';
import ReactGA from "react-ga4";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from './components/Header'
import {StyledEngineProvider, ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./Theme"
import Home from "./routes/home/Home"
import Projects from "./routes/projects/Projects";
import Services from "./routes/services/Services";
import Sectors from "./routes/sectors/Sectors";
import PrivateSector from "./routes/sectors/private_sector/PrivateSector";
import PublicSector from "./routes/sectors/public_sector/PublicSector";
import Contact from "./routes/contact/Contact";
import FurtherEducation from "./routes/sectors/public_sector/further_education/FurtherEducation";
import HigherEducation from "./routes/sectors/public_sector/higher_education/HigherEducation";
import LocalAuthority from "./routes/sectors/public_sector/local_authority/LocalAuthority";
import Construction from "./routes/sectors/private_sector/construction/Construction";
import Infrastructure from "./routes/sectors/private_sector/infrastructure/Infrastructure";
import Energy from "./routes/sectors/private_sector/energy/Energy";
import {useState} from "react";
import Footer from "./components/Footer";
import PrivacyPolicy from "./routes/privacy_policy/PrivacyPolicy";

ReactGA.initialize("G-QJ6ECK2RWM")

function App() {
    const [customTheme,setCustomTheme] =  useState(theme)
    const defaultHeight ="2500px"

    return (
        <ThemeProvider theme={customTheme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline/>
                <BrowserRouter>
                    <Header changeTheme={customTheme} setCustomTheme={setCustomTheme}/>
                    <Routes>
                        <Route exact path={"/"} element={<Home/>}></Route>
                        <Route exact path={"/services"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>
                        <Route exact path={"/sectors"} element={<div style={{height:defaultHeight}}><Sectors/></div>}></Route>
                        <Route exact path={"/projects"} element={<div style={{height:defaultHeight}}><Projects/></div>}></Route>
                        <Route exact path={"/contact"} element={<div style={{height:defaultHeight}}><Contact/></div>}></Route>
                        <Route exact path={"/sectors/private_sector"} element={<div style={{height:defaultHeight}}><PrivateSector/></div>}></Route>
                        <Route exact path={"/sectors/public_sector"} element={<div style={{height:defaultHeight}}><PublicSector/></div>}></Route>

                        <Route exact path={"/services/skills_education_consultancy"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>
                        <Route exact path={"/services/strategic_advisory"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>
                        <Route exact path={"/services/capital_investment_projects"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>
                        <Route exact path={"/services/capital_funding_specialist"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>
                        <Route exact path={"/services/capital_investment_planning"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>
                        <Route exact path={"/services/change_management"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>
                        <Route exact path={"/services/strategic_prioritisation"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>
                        <Route exact path={"/services/skills_education_project_management"} element={<div style={{height:defaultHeight}}><Services/></div>}></Route>

                        <Route exact path={"/sectors/private_sector"} element={<div style={{height:defaultHeight}}><Sectors/></div>}></Route>
                        <Route exact path={"/sectors/public_sector"} element={<div style={{height:defaultHeight}}><Sectors/></div>}></Route>

                        <Route exact path={"/sectors/public_sector/further_education"} element={<div style={{height:defaultHeight}}><FurtherEducation/></div>}></Route>
                        <Route exact path={"/sectors/public_sector/higher_education"} element={<div style={{height:defaultHeight}}><HigherEducation/></div>}></Route>
                        <Route exact path={"/sectors/public_sector/local_authority"} element={<div style={{height:defaultHeight}}><LocalAuthority/></div>}></Route>

                        <Route exact path={"/sectors/private_sector/construction"} element={<div style={{height:defaultHeight}}><Construction/></div>}></Route>
                        <Route exact path={"/sectors/private_sector/energy"} element={<div style={{height:defaultHeight}}><Energy/></div>}></Route>
                        <Route exact path={"/sectors/private_sector/infrastructure"} element={<div style={{height:defaultHeight}}><Infrastructure/></div>}></Route>
                        <Route exact path={"/privacy"} element={<div style={{height:defaultHeight}}><PrivacyPolicy/></div>}></Route>


                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </StyledEngineProvider>

        </ThemeProvider>
    );
}

export default App;
