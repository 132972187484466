import React, {useEffect, useState} from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker
} from "react-simple-maps";
import geoUrl from '../../data/uk.json'
import {useTheme} from "@mui/styles";
import {FormControlLabel, Grid, Tooltip, tooltipClasses, Typography} from "@mui/material";
import {styled} from '@mui/material/styles';
import staff_room from '../../images/staff_room.png'
import constructionOne from '../../images/construction_1.png'
import constructionTwo from '../../images/construction_2.png'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const HtmlTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function Projects() {
  const theme = useTheme()
  const center = {x:-2.90120, y:53.16132}
  const zoom = 10

  const [display, setDisplay] = useState("sector")

  const sectorKeys = [
    {
      x: -15.90120,
      y: 58.0,
      name: "Public Sector",
      xOffset: 3,
      yOffset: 0.28,
      anchor: "start",
      color: theme.palette.common.publicSector,
      textColor: "white",
      fontSize: "1px",
      tooltip: "Public Sector projects",
      image: undefined,
      sectorColor: theme.palette.common.publicSector,
      markerSize:0.5
    },
    {
      x: -15.90120,
      y: 57.5,
      name: "Private Sector",
      xOffset: 3,
      yOffset: 0.28,
      anchor: "start",
      color: theme.palette.common.privateSector,
      textColor: "white",
      fontSize: "1px",
      tooltip: "Private Sectory projects",
      image: undefined,
      sectorColor: theme.palette.common.privateSector,
      markerSize:0.5
    },

  ]

  const subSectorKeys = [
    {
      x: -15.90120,
      y: 58.0,
      name: "Energy",
      xOffset: 3,
      yOffset: 0.28,
      anchor: "start",
      color: theme.palette.common.energySector,
      textColor: "white",
      fontSize: "1px",
      tooltip: "Energy projects",
      image: undefined,
      sectorColor: theme.palette.common.energySector,
      markerSize:0.5
    },
    {
      x: -15.90120,
      y: 57.5,
      name: "Infrastructure",
      xOffset: 3,
      yOffset: 0.28,
      anchor: "start",
      color: theme.palette.common.infrastructureSector,
      textColor: "white",
      fontSize: "1px",
      tooltip: "Infrastructure projects",
      image: undefined,
      sectorColor: theme.palette.common.infrastructureSector,
      markerSize:0.5
    },
    {
      x: -15.90120,
      y: 57,
      name: "Construction",
      xOffset: 3,
      yOffset: 0.28,
      anchor: "start",
      color: theme.palette.common.constructionSector,
      textColor: "white",
      fontSize: "1px",
      tooltip: "Construction projects",
      image: undefined,
      sectorColor: theme.palette.common.constructionSector,
      markerSize:0.5
    },
    {
      x: -15.90120,
      y: 56.5,
      name: "Further Education",
      xOffset: 3,
      yOffset: 0.28,
      anchor: "start",
      color: theme.palette.common.furtherEducationSector,
      textColor: "white",
      fontSize: "1px",
      tooltip: "Further Education projects",
      image: undefined,
      sectorColor: theme.palette.common.furtherEducationSector,
      markerSize:0.5
    },
    {
      x: -15.90120,
      y: 56,
      name: "Higher Education",
      xOffset: 3,
      yOffset: 0.28,
      anchor: "start",
      color: theme.palette.common.higherEducationSector,
      textColor: "white",
      fontSize: "1px",
      tooltip: "Higher Education projects",
      image: undefined,
      sectorColor: theme.palette.common.higherEducationSector,
      markerSize:0.5
    },
    {
      x: -15.90120,
      y: 55.5,
      name: "Local Authority",
      xOffset: 3,
      yOffset: 0.28,
      anchor: "start",
      color: theme.palette.common.localAuthoritySector,
      textColor: "white",
      fontSize: "1px",
      tooltip: "Local Authority projects",
      image: undefined,
      sectorColor: theme.palette.common.localAuthoritySector,
      markerSize:0.5
    }
  ]

  const markers = [
    {
      x: -2.90120,
      y: 53.16132,
      name: "University of Awesome",
      xOffset: 2.5,
      yOffset: 1.75,
      anchor: "middle",
      color: theme.palette.common.publicSector,
      textColor: theme.palette.common.quetraGreen,
      fontSize: ".65px",
      tooltip: "First university in UK to specialise in post Trump political theory",
      image: staff_room,
      sectorColor: theme.palette.common.higherEducationSector,
      markerSize:0.6
    },
    {
      x: -3.90120,
      y: 51.06132,
      name: "McDonalds",
      xOffset: 1.1,
      yOffset: 1.25,
      anchor: "middle",
      color: theme.palette.common.privateSector,
      textColor: theme.palette.common.quetraGreen,
      fontSize: ".65px",
      tooltip: "McDonalds flasgship vegan concept restaurant",
      image: undefined,
      sectorColor: theme.palette.common.constructionSector,
      markerSize:0.35
    },
    {
      x: -3.90120,
      y: 57.06132,
      name: "A Haggis Factory",
      xOffset: 1.1,
      yOffset: -1.25,
      anchor: "middle",
      color: theme.palette.common.privateSector,
      textColor: theme.palette.common.quetraGreen,
      fontSize: ".65px",
      tooltip: "Chief consultant on largest Haggis factory in Europe",
      image: constructionOne,
      sectorColor: theme.palette.common.infrastructureSector,
      markerSize:0.5
    },
    {
      x: -0.90120,
      y: 52.06132,
      name: "A Public toilet",
      xOffset: 1.1,
      yOffset: 1.25,
      anchor: "middle",
      color: theme.palette.common.publicSector,
      textColor: theme.palette.common.quetraGreen,
      fontSize: ".65px",
      tooltip: "Highest throughput public toilet in western hermisphere (includes bidet)",
      image: constructionTwo,
      sectorColor: theme.palette.common.localAuthoritySector,
      markerSize:0.4
    },
    {
      x: -0.90120,
      y: 54.06132,
      name: "Windfarm",
      xOffset: 1.1,
      yOffset: 1.45,
      anchor: "end",
      color: theme.palette.common.privateSector,
      textColor: theme.palette.common.quetraGreen,
      fontSize: ".65px",
      tooltip: "Generates wind power for approximately 27 minutes a year",
      image: undefined,
      sectorColor: theme.palette.common.energySector,
      markerSize:0.9
    },
    {
      x: -1.9120,
      y: 54.90,
      name: "Grange Hill",
      xOffset: 1.1,
      yOffset: 1.25,
      anchor: "end",
      color: theme.palette.common.publicSector,
      textColor: theme.palette.common.quetraGreen,
      fontSize: ".65px",
      tooltip: "Added Sausages to Canteen menu",
      image: undefined,
      sectorColor: theme.palette.common.furtherEducationSector,
      markerSize:0.5
    }
  ]

  const usedKeys = display === "sector" ? sectorKeys : subSectorKeys

  useEffect(() => {
    document.title = `${process.env.REACT_APP_COMPANY_NAME} - Projects`
  }, []);

  return (
    <Grid container>
      <Grid container item direction={"column"} justifyContent={"center"} alignItems={"center"}
            style={{padding: "1em"}}>
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-labelledby="sector-radio-buttons-group-label"
            name="sector-radio-buttons-group"
            value={display}
            onChange={(event) => setDisplay(event.target.value)}
          >
            <FormControlLabel value="sector" control={
              <Radio sx={{
                color: "#fff",
                '&.Mui-checked': {
                  color: theme.palette.common.quetraGold,
                },
              }}
              />} label="Sector"/>
            <FormControlLabel value="subsector" control={
              <Radio sx={{
                color: "#fff",
                '&.Mui-checked': {
                  color: theme.palette.common.quetraGold,
                },
              }}/>} label="Sub-Sector"/>
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid container item style={{marginTop: "1em"}}>
        <Grid item xs={12}>
          <ComposableMap projection="geoMercator" center={[center.x, center.y]}>
            <ZoomableGroup center={[center.x, center.y]} zoom={zoom} minZoom={zoom}>
              <Geographies geography={geoUrl}>
                {({geographies}) =>
                  geographies.map((geo) => (
                    <Geography key={geo.rsmKey} geography={geo} fill={"#c9dabf"}/>
                  ))
                }
              </Geographies>

              {usedKeys.map(m => {
                return (
                  <HtmlTooltip title={
                    <React.Fragment>
                      <Typography color="inherit">{m.name}</Typography>
                      {m.tooltip}
                      {m.image ? <img src={m.image} width={"100%"} alt={"img"}/> : undefined}
                    </React.Fragment>
                  }>
                    <Marker coordinates={[m.x, m.y]}>
                      <circle r={m.markerSize} fill={m.color}/>
                      <text
                        textAnchor={m.anchor}
                        y={m.yOffset}
                        x={m.xOffset}
                        style={{fontFamily: "roboto", fill: m.textColor, fontSize: m.fontSize}}
                      >
                        {m.name}
                      </text>
                    </Marker>
                  </HtmlTooltip>
                )
              })}

              {markers.map(m => {
                return (
                  <HtmlTooltip title={
                    <React.Fragment>
                      <Typography color="inherit">{m.name}</Typography>
                      {m.tooltip}
                      {m.image ? <img src={m.image} width={"100%"} alt={"img"}/> : undefined}
                    </React.Fragment>
                  }>
                    <Marker coordinates={[m.x, m.y]}>
                      <circle r={m.markerSize} fill={display === "sector" ? m.color : m.sectorColor}/>
                      <text
                        textAnchor={m.anchor}
                        y={m.yOffset}
                        x={m.xOffset}
                        style={{fontFamily: "roboto", fill: m.textColor, fontSize: m.fontSize}}
                      >
                        {m.name}
                      </text>
                    </Marker>
                  </HtmlTooltip>
                )
              })}
            </ZoomableGroup>
          </ComposableMap>
        </Grid>
      </Grid>
    </Grid>
  )
}
