import {createTheme} from "@mui/material";
import { yellow, green, lightBlue,blue, deepOrange } from '@mui/material/colors';

const quetraBlack = "#0C0123"
const quetraGreen = "#023560"
const quetraGold = "#FCBF1E"
const quetraYellow = "#FFCB9A"
const quetraGrey = "#40BAD5"
const quertaSuccess ="#b0e59d"
const quertaFailure= "#e38383"

// https://mui.com/material-ui/customization/default-theme/

export default createTheme({
    palette: {
        common: {
            quetraBlack: `${quetraBlack}`,
            quetraGreen: `${quetraGreen}`,
            quetraGold: `${quetraGold}`,
            quetraGrey : `${quetraGrey}`,
            quetraYellow : `${quetraYellow}`,
            quertaSuccess : `${quertaSuccess}`,
            quertaFailure:`${quertaFailure}`,
            publicSector:"#1bb62e",
            privateSector:"#1b30b6",
            energySector:yellow[700],
            infrastructureSector:green[700],
            constructionSector:deepOrange[700],
            furtherEducationSector:lightBlue[300],
            higherEducationSector:blue[900],
            localAuthoritySector:"#326872",
        },
        primary: {
            main: `${quetraGreen}`
        },
        secondary: {
            main: `${quetraGold}`
        },
        headerBackground : {
            main: `${quetraBlack}`
        },
        background :{
            default:`${quetraGreen}`,
            paper:`${quetraGreen}`
        },
        text:{
            fontFamily:"Raleway",
            primary:'#fff'
        },
        menuItem:{
            highlight:`${quetraGold}`,
        },
        icon :{
            main:"white"
        }

    },
    typography: {
        body1:{
            color: '#fff',
            fontFamily: "Raleway",
            fontWeight: "400",
            fontSize: "1.25em",
        },
        h3:{
            fontFamily:"Roboto",
            color: '#fff',
            fontWeight:"700"
        },
        h4:{
            fontFamily:"Raleway",
            color:'#fff',
            fontWeight:"900"
        },
        tab:{
            color: '#fff',
            fontFamily: "Raleway",
            textTransform: "none",
            fontWeight: "700",
            fontSize: "1rem",
            opacity:0.95,
            "&:hover": {
                opacity:1
            }
        }
    },
})

