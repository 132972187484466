import React, {useState, useEffect} from "react";
import ReactGA from "react-ga4";
import {AppBar, Button, IconButton, ListItemButton, Tab, Tabs} from "@mui/material";
import {makeStyles} from "@mui/styles"
import {Toolbar} from "@mui/material";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {Typography} from "@mui/material";
import {Link} from 'react-router-dom'
import theme from "../Theme";
import logo from '../logo.png'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {routes} from '../routes/Routes'
import ThemeManager from "./ThemeManager";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/styles";
import {SwipeableDrawer} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {List} from "@mui/material";
import {ListItemText} from "@mui/material";

const useStyles = makeStyles(theme => ({
    toolbarMargin: {
        ...theme.mixins.toolbar,
        marginBottom: '2.2em'
    },
    drawerMargin: {
        ...theme.mixins.toolbar,
        marginBottom: '1.2em'
    },
    logo: {
        height: '4em',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '10px',
        marginTop: '4px',
        [theme.breakpoints.down("md")]: {
            marginTop: '7px',
            height: '2rem',
        }
    },
    tabContainer: {
        marginLeft: 'auto',
        marginRight: '0.1em'
    },
    tab: {
        ...theme.typography.tab,
        minWidth: 100,
    },
    menu: {
        backgroundColor: theme.palette.headerBackground.main,
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.headerBackground.main,
        }
    },
    menuItem: {
        ...theme.typography.tab,
        "&:hover": {
            color: theme.palette.menuItem.highlight,
            opacity: 1
        }
    },
    drawer: {
        backgroundColor: theme.palette.headerBackground.main
    },
    drawerItem: {
        ...theme.typography.tab
    },

    drawerIcon: {
        height: "50px",
        width: "50px"
    },
    drawerIconContainer: {
        color: "white",
        marginLeft: 'auto',
        "&:hover": {
            backgroundColor: 'transparent',
        }
    },
    appBar :{
        zIndex:theme.zIndex.modal +1
    }
}))

function ElevationScroll(props) {
    const {children} = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const getTabColor = (isActive) => {
    return isActive ? theme.palette.menuItem.highlight : undefined
}

function MainMenuTab(props) {
    const {
        item,
        selected,
        classes,
        showSubMenu,
        onChange,
        indicator,
        selectionFollowsFocus,
        textColor,
        value,
        onClick
    } = props
    const {index, enabled, label, to, menu} = item
    return (
        <Tab
            onClick={onClick}
            onChange={onChange}
            indicator={indicator}
            selectionFollowsFocus={selectionFollowsFocus}
            textColor={textColor}
            value={value}
            key={index}
            style={{color: getTabColor(selected)}}
            disableRipple
            className={classes.tab}
            component={Link}
            disabled={enabled === false}
            to={to}
            label={label}
            aria-owns={menu ? showSubMenu ? menu.name : undefined : undefined}
            aria-haspopup={showSubMenu ? true : undefined}/>
    )
}

function SubMenu(props) {
    const {index, item, open, onClose, setMenuSelected, classes, anchorElement} = props
    const {menu} = item

    if (!menu) {
        return undefined
    }
    return (
        <Menu
            key={index}
            id={menu.name}
            classes={{paper: classes.menu}}
            anchorEl={anchorElement}
            open={open}
            onClose={() => {
                onClose(undefined, index)
            }}
            MenuListProps={{onMouseLeave: () => onClose(undefined, index)}}>
            {menu.items.map((item, index) => {
                return (
                    <MenuItem key={index} component={Link}
                              to={item.to}
                              classes={{root: classes.menuItem}}
                              selected={window.location.pathname === item.to}
                              onClick={(event) => {
                                  onClose(event, index);
                                  setMenuSelected(index);

                              }}>
                        {item.label}
                    </MenuItem>
                )
            })}
        </Menu>
    )
}

export default function Header(props) {
    const {setCustomTheme} = props
    const classes = useStyles();
    const theme = useTheme();
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const matches = useMediaQuery(theme.breakpoints.down("lg"))
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openThemeManager, setOpenThemeManager] = useState(false);
    const [menuSelected, setMenuSelected] = useState(0)
    const [anchorElement, setAnchorElement] = useState(null)
    const [openMenu, setOpenMenu] = useState(-1)
    const [previousURL, setPreviousURL] = useState("")

    const handleThemeClickOpen = () => {
        setOpenThemeManager(true);
    };

    const handleThemeClose = () => {
        setOpenThemeManager(false);
    };

    function onMenuChanged(event, newValue) {
        setMenuSelected(newValue)
    }

    const handleMenuOpen = (e, index) => {
        setAnchorElement(e.currentTarget)
        setOpenMenu(index)
    }

    const handleMenuClose = () => {
        setAnchorElement(null)
        setOpenMenu(-1)
    }

    useEffect(() => {
        if (previousURL !== window.location.pathname){
            setPreviousURL(window.location.pathname)
            ReactGA.send("pageview");
        }
        const activeTab = routes.find(tab => tab.to === window.location.pathname ? tab : undefined)
        const activeSubTab = routes.find(tab => tab.menu ? tab.menu.items.find(item => item.to === window.location.pathname) : false)
        setMenuSelected(activeTab ? activeTab.index : activeSubTab ? activeSubTab.index : routes[0])
    }, [menuSelected])

    const tabs = (
        <React.Fragment>

            <Tabs value={menuSelected}
                  onChange={onMenuChanged}
                  aria-label={"main menu"}
                  className={classes.tabContainer}
                  TabIndicatorProps={{style: {background: theme.palette.menuItem.highlight}}}>
                {routes.map((tab) => {
                    return (
                        <MainMenuTab key={tab.index}
                                     item={tab}
                                     selected={menuSelected === tab.index}
                                     classes={classes}
                                     onClick={event => handleMenuOpen(event, tab.index)}
                                     showSubMenu={!!anchorElement && openMenu === tab.index}/>)
                })}
            </Tabs>
            <Button style={{marginRight: "1em"}} onClick={handleThemeClickOpen}>
                <Typography variant={"body1"}>
                    Theme
                </Typography>
            </Button>

        </React.Fragment>
    )

    const drawer = (
        <React.Fragment>
            <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
                classes={{paper: classes.drawer}}
            >
                <div className={classes.drawerMargin}/>
                <List disablePadding>
                    {routes.map(route => {
                        return (
                            <ListItemButton key={route.index}
                                            divider
                                            component={Link}
                                            to={route.to}
                                            onClick={(event) => {
                                                handleMenuOpen(event, route.index);
                                                setMenuSelected(route.index)
                                            }}
                                            selected={menuSelected === route.index}>
                                <ListItemText disableTypography
                                              className={classes.drawerItem}
                                              style={{color: menuSelected === route.index ? theme.palette.menuItem.highlight : undefined}}
                                >
                                    {route.label}
                                </ListItemText>
                            </ListItemButton>
                        )
                    })}
                </List>
            </SwipeableDrawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)} disableRipple
                        className={classes.drawerIconContainer}>
                <MenuIcon className={classes.drawerIcon}/>
            </IconButton>
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <ElevationScroll>
                <AppBar position={"fixed"} color={"headerBackground"} className={classes.appBar}>
                    <Toolbar disableGutters={true}>
                        <Button component={Link} to={"/"}
                                disableRipple
                                onClick={() => onMenuChanged(0)}>
                            <img alt="company logo" src={logo} className={classes.logo}/>
                            <Typography variant={matches ? "h4" : "h3"}>
                                {process.env.REACT_APP_COMPANY_NAME}
                            </Typography>
                        </Button>
                        {matches ? drawer : tabs}
                        {routes.map((tab) => {
                            return (
                                <SubMenu key={tab.index}
                                         index={tab.index}
                                         item={tab}
                                         open={openMenu === tab.index}
                                         onClose={() => {
                                             handleMenuClose()
                                         }}
                                         setMenuSelected={setMenuSelected}
                                         classes={classes}
                                         anchorElement={anchorElement}/>)
                        })}
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <div className={classes.toolbarMargin}/>
            <ThemeManager open={openThemeManager} handleClose={handleThemeClose} theme={theme}
                          handleThemeChange={setCustomTheme}/>
        </React.Fragment>
    )
}
