import React, {useEffect, useState} from "react";
import {Button, Grid, TextField, Link, Typography, useMediaQuery} from "@mui/material";
import {makeStyles, useTheme} from "@mui/styles";
import MaterialUiPhoneNumberTextField from '@alisson-amaral/material-ui-phone-number-text-field';
import axios from 'axios'
import {CircularProgress} from "@mui/material";
import {Snackbar} from "@mui/material";
import {SnackbarContent} from "@mui/material";
import ReactGA from "react-ga4";

const useStyles = makeStyles(theme => ({
  contactForm: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: 10,
    width: "50em",
    height: "100%",
    minWidth: "20em",
    minHeight: "30em",
    elevation: 200
  },
  contactText: {
    color: "black",
  },
  'input': {
    color: "black",
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: "black",
      fontSize: "20px",
      fontWeight: "700"
    }
  },
  flagDropdown: {
    height: "500px",
    color: "black",
    sx: {
      "& .MuiInputBase-root": {
        color: 'black',
        backgroundColor: "white"
      }
    }
  },
  phoneInput: {
    backgroundColor: "white",
    color: "primary"
  },
  markerPositon: {
    marginTop: "4em"
  }
}))

function Contact() {

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [eMail, setEMail] = useState('')
  const [phone, setPhone] = useState('44')
  const [message, setMessage] = useState('')
  const [mailing, setMailing] = useState(false);

  const [alert, setAlert] = useState({open: false, message: '', backgroundColor: ''})

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleOrganisationChange = (event) => {
    setOrganisation(event.target.value);
  };

  const handleEMailChange = (event) => {
    setEMail(event.target.value);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const onClearForm = () => {
    setPhone('44')
    setName('')
    setRole('')
    setEMail('')
    setOrganisation('')
    setMessage('')
  }

  const onConfirm = () => {
    setMailing(true)
    axios.get(
      'https://us-central1-quetra.cloudfunctions.net/sendMail',
      {
        params: {
          name:name,
          email:eMail,
          phone:phone,
          message:message,
          role:role,
          organisation:organisation
        }
      })
      .then(() => {
        setMailing(false);
        onClearForm();
        setAlert({
          open: true,
          message: "Message Sent Successfully.",
          backgroundColor: theme.palette.common.quertaSuccess
        })

        ReactGA.event("messageSent",{
          category: "communications",
          event_name: "messageSent",
          label: "message", // optional
          nonInteraction: false, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
      })

      .catch(() => {
        setMailing(false);
        setAlert({
          open: true,
          message: "Message failed to sent. Please try again",
          backgroundColor: theme.palette.common.quertaFailure
        })
      })
  };

  useEffect(() => {
    document.title = `${process.env.REACT_APP_COMPANY_NAME} - Contact`
  }, []);

  return (
    <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} style={{padding: "1em"}}>
      <Snackbar open={alert.open}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                autoHideDuration={"4000"}
                onClose={() => setAlert({...alert, open: false})}>
        <SnackbarContent style={{backgroundColor: alert.backgroundColor,}}
                         message={<span id="client-snackbar">{alert.message}</span>}/>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant={"h3"}>
          How can we help?
        </Typography>
      </Grid>
      <Grid container item xs={12} direction={"row"} alignItems={"flex-start"} justifyContent={"space-evenly"}
            spacing={2} style={{marginTop: "2em"}}>
        <Grid item direction={"column"} style={{width: "400px", marginBottom: "2em"}}>
          <Grid item alignItems={"center"}>
            <Grid container item alignItems={matchesSM ? "center" : "flex-start"} direction={"column"}>
              <Grid item>
                <Typography variant={"h4"}>
                  Registered Address
                </Typography>
              </Grid>
            </Grid>
            <Grid container item alignItems={matchesSM ? "center" : "flex-start"} direction={"column"}>
              <Grid item>
                <Typography variant={"body1"}>
                  2 Hilliards Court
                </Typography>
              </Grid>
            </Grid>
            <Grid container item alignItems={matchesSM ? "center" : "flex-start"} direction={"column"}>
              <Grid item>
                <Typography variant={"body1"}>
                  Chester Business Park
                </Typography>
              </Grid>
            </Grid>
            <Grid container item alignItems={matchesSM ? "center" : "flex-start"} direction={"column"}>
              <Grid item>
                <Typography variant={"body1"}>
                  Chester
                </Typography>
              </Grid>
            </Grid>
            <Grid container item alignItems={matchesSM ? "center" : "flex-start"} direction={"column"}>
              <Grid item>
                <Typography variant={"body1"}>
                  CH4 9QP
                </Typography>
              </Grid>
            </Grid>

          </Grid>
          <Grid item style={{marginTop: '2em'}}>
            <Grid container item alignItems={matchesSM ? "center" : "flex-start"} direction={"column"}>
              <Grid item>
                <Typography variant={"h4"}>E-Mail</Typography>
              </Grid>
            </Grid>
            <Grid container item alignItems={matchesSM ? "center" : "flex-start"} direction={"column"}>
              <Grid item>
                <Typography variant={"body1"}
                            color={"white"}
                            component={Link}
                            href={"mailto://hayden.smith@gmail.com"}>
                  hayden.smith@gmail.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item direction={"column"}
              style={{marginLeft: "1em", width: matches ? "400px" : "600px", border: "1px solid"}}>
          <Typography variant={"h4"}>Contact Us</Typography>
          <Grid container item direction={"row"}
                style={{padding: ".5em", paddingRight: "1.4em", paddingBottom: "1.5em"}}
                justifyContent={"space-evenly"}
          >
            <Grid item xs={12} style={{backgroundColor: "white"}}>
              <TextField size="small"
                         placeholder="Name"
                         InputProps={{classes: {input: classes['input']}}}
                         fullWidth
                         value={name} onChange={handleNameChange}/>
            </Grid>
            <Grid item xs={12} style={{backgroundColor: "white", marginTop: ".5em"}}>
              <TextField InputProps={{classes: {input: classes['input']}}} size="small"
                         className={classes.contactText} placeholder="Role"
                         value={role} onChange={handleRoleChange}
                         fullWidth></TextField>
            </Grid>
            <Grid item xs={12} style={{backgroundColor: "white", marginTop: ".5em"}}>
              <TextField InputProps={{classes: {input: classes['input']}}} size="small"
                         className={classes.contactText}
                         placeholder="Organisation" fullWidth
                         value={organisation} onChange={handleOrganisationChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} style={{backgroundColor: "white", marginTop: ".5em"}}>
              <TextField InputProps={{classes: {input: classes['input']}}} size="small"
                         className={classes.contactText} placeholder="E-Mail"
                         fullWidth value={eMail} onChange={handleEMailChange}></TextField>
            </Grid>
            <Grid item xs={12} style={{marginTop: ".5em"}}>
              <MaterialUiPhoneNumberTextField
                sx={{
                  "& .MuiInputBase-root": {
                    color: 'black'
                  }
                }}
                defaultCountry={"gb"}
                color={"primary"}
                fullWidth
                inputClass={classes.phoneInput}
                preferredCountries={['gb', 'ie']}
                value={phone}
                buttonStyle={{borderRadius: '500px 0 0 5px'}}
                dropdownClass={classes.flagDropdown}
                onChange={handlePhoneChange}/>
            </Grid>
            <Grid item xs={12} style={{backgroundColor: "white", marginTop: ".5em"}}>
              <TextField InputProps={{classes: {input: classes['input']}}} size="small"
                         className={classes.contactText} placeholder="Message"
                         multiline rows={10} fullWidth value={message}
                         onChange={handleMessageChange}
              ></TextField>
            </Grid>
            <Grid item xs={12} style={{marginTop: ".5em"}} alignItems={"center"}>
              <Button
                disableRipple
                disableFocusRipple
                color={"secondary"}
                style={{width: "100%", backgroundColor: 'transparent'}}
                onClick={onConfirm}>
                {mailing ? <CircularProgress color={"secondary"}/> : "Send"}
              </Button>

            </Grid>

          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default Contact
