import React, {useEffect} from "react";

export default function Sectors() {

    useEffect(() => {
        document.title = `${process.env.REACT_APP_COMPANY_NAME} - Sectors`
    }, []);

    return (
        <div>Sectors {window.location.pathname}</div>
    )
}
