import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";

export default function Services() {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_COMPANY_NAME} - Services`
  }, []);
  return (
    <Grid container columns style={{padding: "1em", alignItems: "center"}}>
      <Grid item xs={4}>
        <Typography variant={"h2"}>
          Our Services are Awesome, Especially {`${window.location.pathname}`}
        </Typography>
      </Grid>
    </Grid>
  )
}
