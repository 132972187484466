import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";

export default function HigherEducation() {
  useEffect(() => {
    document.title = `${process.env.REACT_APP_COMPANY_NAME} - Public Sector - Higher Education`
  }, []);
    return (
        <Grid container columns style={{padding: "1em", alignItems: "center"}}>
            <Grid item xs={4}>
                <Typography variant={"h2"}>
                    Higher Education Rocks
                </Typography>
            </Grid>
        </Grid>
    )
}
