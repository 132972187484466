export const routes = [{
    index: 0,
    enabled: true,
    label: "Home",
    to: "/"
}, {
    index: 1,
    enabled: true,
    label: "Services",
    to: "/services",
    menu: {
        name: 'services-menu',
        items: [
            {
                enabled: true,
                label: "Skills & Education Consultancy",
                to: "/services/skills_education_consultancy",
            },
            {
                enabled: true,
                label: "Strategic Advisory",
                to: "/services/strategic_advisory",
            },
            {
                enabled: true,
                label: "Capital Investment Project Delivery",
                to: "/services/capital_investment_projects",
            },
            {
                enabled: true,
                label: "Capital Funding Specialist",
                to: "/services/capital_funding_specialist",
            }
            , {
                enabled: true,
                label: "Capital Investment Planning",
                to: "/services/capital_investment_planning",
            },
            {
                enabled: true,
                label: "Change Management",
                to: "/services/change_management",
            },
            {
                enabled: true,
                label: "Strategic Prioritisation",
                to: "/services/strategic_prioritisation",
            },
            {
                enabled: true,
                label: "Skills & Education Project Management",
                to: "/services/skills_education_project_management",
            }
        ]
    }
}, {
    index: 2,
    enabled: true,
    label: "Public Sector",
    to: "/sectors/public_sector",
    menu: {
        name: 'public-sector-menu',
        items: [{
            enabled: true,
            label: "Further Education",
            to: "/sectors/public_sector/further_education",
        },
            {
                enabled: true,
                label: "Higher Education",
                to: "/sectors/public_sector/higher_education",
            },
            {
                enabled: true,
                label: "Local Authority",
                to: "/sectors/public_sector/local_authority",
            }]
    }
}, {
    index: 3,
    enabled: true,
    label: "Private Sector",
    to: "/sectors/private_sector",
    menu: {
        name: 'private-sector-menu',
        items: [
            {
                enabled: true,
                label: "Construction",
                to: "/sectors/private_sector/construction",
            },
            {
                enabled: true,
                label: "Energy",
                to: "/sectors/private_sector/energy",
            },
            {
                enabled: true,
                label: "Infrastructure",
                to: "/sectors/private_sector/infrastructure",
            }]
    }
}, {
    index: 4,
    enabled: false,
    label: "Projects",
    to: "/projects"
}, {
    index: 5,
    enabled: true,
    label: "Contact",
    to: "/contact"
}
]
